import './App.css';
import Navbar from './Components/Navbar.jsx';
import View from './Components/View.jsx';
import Services from './Components/Services.jsx'
import Title from './Components/title.jsx'
import About from './Components/about.jsx';
import Contact from './Components/Contact.jsx';
import Footer from './Components/Footer.jsx';




function App() {


  
  
  







  return (
    <div className="App">
      <Navbar/>
      <View/>

      <div className='container'>
        
      <Title subTitle='Our SERVICES' title='What We Offer'/>
      <Services/>
      <About/>
      <Title subTitle='Contact Us' title='Get in Touch'/>
      <Contact/>

      </div>
      <Footer/>

      


      
    </div>
  );
}


export default App;

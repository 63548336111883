import React, { useEffect, useState } from 'react'
import '../style/View.css'
import call from '../assets/telephone.png'

function View() {
  const [headline, setHeradline] = useState('');


  useEffect(()=>{
    const searchParams = new URLSearchParams(window.location.search);
    const query = searchParams.get('lg');
    const queryL = searchParams.get('LG');
    const query2L = searchParams.get('samsung')
    const query2 = searchParams.get('Samsung')

    const query3L = searchParams.get('bosh')
    const query3 = searchParams.get('Bosh')


    try{


  

    if (query || queryL==='true'){
      setHeradline('LG service center')
    }else if(query2 || query2L==='true'){
      setHeradline('Samsung servie Help')
    } else if(query3 || query3L==='true') {
      setHeradline('Bosh Service Help')
    } else{
      setHeradline('Repair and Service')
    } 
  }catch (error){
    if(error instanceof URIError){
      console.error('URIError parsing query parameter',error);
      setHeradline('Service And Repair = URIERROR')
    }
    console.error('Error Parsing query parameter',error);
    setHeradline('Repair and Service = D')
  }
    
    
    
      
    



  },[])





  return (
    <div className='view container' >
        <div className='hero-text'>
          <h1>{headline}</h1>
            {/* <h1>LG Service Center </h1> */}
            <p>Refridgerator, washing Machine, Air Conditioner & Dish Washer Repair
              Service & Maintenance
              Single Visit Solution On Same Day Of Booking
            </p>
            <a className='btn' href='tel:+917770035469'> <img src={call} alt=''/> 1800-795-555</a>
        </div>

      
    </div>
  )
}

export default View
